<template>
  <div>
    <span>
      {{ wordsFirstLetterToUpper(userRoles) }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { getUserRoles, wordsFirstLetterToUpper } from "@/helpers/formatting";
import type { IUser } from "@/models/users";
import type { PropType } from "vue";
import { computed } from "vue";

const props = defineProps({
  model: {
    type: Object as PropType<IUser>,
    required: true
  }
});

const userRoles = computed(() =>
  props.model?.roles?.length ? getUserRoles(props.model.roles).join(", ") : "-"
);
</script>
